\<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <!-- <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-text
            label="감지기NO"
            name="gasDetectorNo"
            v-model="searchParam.gasDetectorNo">
          </c-text>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-text
            label="감지대상"
            name="sensingObject"
            v-model="searchParam.sensingObject">
          </c-text>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-text
            label="설치장소"
            name="installLocation"
            v-model="searchParam.installLocation">
          </c-text>
        </div> -->
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-process
            label="관련공정"
            multiple="single"
            name="processCd"
            v-model="searchParam.processCd">
          </c-process>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-dept
            type="search"
            label="관리부서"
            name="deptCd"
            v-model="searchParam.deptCd">
          </c-dept>
        </div>
      </template>
    </c-search-box>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <c-table
          ref="gasleak"
          title="가스누출감지 경보기 설치 목록"
          tableId="gasleak"
          :columns="grid.columns"
          :gridHeight="grid.height"
          :data="grid.data"
          rowKey="psiGasAlarmId"
          :columnSetting="false"
          :expandAll="true"
          @linkClick="linkClick"
        >
          <!-- 버튼 영역 -->
          <template slot="table-button">
            <q-btn-group outline >
              <c-btn
                v-if="editable"
                label="엑셀업로드"
                icon="upload"
                @btnClicked="excelUploadData" />
                  <c-btn v-if="editable" label="등록" icon="add" @btnClicked="equipmentPopup" />
                  <c-btn label="검색" icon="search" @btnClicked="getList" />
            </q-btn-group>
          </template>
        </c-table>
      </div>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'gas-leak',
  data() {
    return {
      grid: {
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            label: '사업장',
            align: 'center',
            style: 'width:100px',
            sortable: true,
            fix: true,
          },
          {
            name: 'revisionNum',
            field: 'revisionNum',
            label: 'Rev.',
            align: 'center',
            style: 'width:50px',
            sortable: true,
            fix: true,
          },
          {
            name: 'gasDetectorNo',
            field: 'gasDetectorNo',
            label: '가스감지기NO',
            align: 'center',
            style: 'width:120px',
            type: 'link',
            sortable: true,
            fix: true,
          },
          {
            name: 'sensingObject',
            field: 'sensingObject',
            label: '감지대상',
            align: 'center',
            style: 'width:120px',
            sortable: true,
            fix: true,
          },
          {
            name: 'installLocation',
            field: 'installLocation',
            label: '설치지역',
            align: 'center',
            style: 'width:130px',
            sortable: true,
            fix: true,
          },
          {
            name: 'operationTime',
            field: 'operationTime',
            label: '작동시간',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
          {
            name: 'detectMethod',
            field: 'detectMethod',
            label: '측정방식',
            align: 'center',
            sortable: true,
          },
          {
            label: '경보설정값',
            align: 'center',
            child: [
              {
                name: 'alarmHighValue',
                field: 'alarmHighValue',
                label: 'high',
                align: 'center',
                style: 'width:90px',
                sortable: true,
              },
              {
                name: 'alarmHighhighValue',
                field: 'alarmHighhighValue',
                label: 'high high',
                align: 'center',
                style: 'width:90px',
                sortable: true,
              },
            ]
          },
          {
            name: 'alarmSettingLocation',
            field: 'alarmSettingLocation',
            label: '경보기설치위치',
            align: 'center',
            style: 'width:150px',
            sortable: true,
          },
          {
            name: 'precisionVal',
            field: 'precisionVal',
            label: '정밀도',
            align: 'center',
            style: 'width:80px',
            sortable: true,
          },
          {
            name: 'measureInfo',
            field: 'measureInfo',
            label: '조치내용',
            align: 'center',
            style: 'width:200px',
            sortable: true,
          },
          {
            name: 'management',
            field: 'management',
            label: '유지관리',
            style: 'width:120px',
            align: 'center',
            sortable: true,
          },
          {
            name: 'remark',
            field: 'remark',
            label: '비고',
            style: 'width:120px',
            align: 'left',
            sortable: true,
          },
        ],
        data: [],
      },
      searchParam: {
        plantCd: '',
        gasDetectorNo: '',
        sensingObject: '',
        installLocation: '',
        processCd: '',
        deptCd: '',
      },
      editable: true,
      listUrl: '',
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      // api scope
      this.listUrl = selectConfig.psi.lbe.gasalarm.list.url;
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    linkClick(row) {
      this.equipmentPopup(row);
    },
    equipmentPopup(result) {
      this.popupOptions.target = () => import(`${"./gasLeakDetectionAlarmInfoDetail.vue"}`);
      this.popupOptions.title = '가스누출감지 경보기 설치 상세'; 
      this.popupOptions.param = {
        psiGasAlarmId: result ? result.psiGasAlarmId : '',
      };
      this.popupOptions.width = '80%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
excelUploadData() {
  this.popupOptions.title = '가스누출감지 경보기 설치계획 목록 업로드';
  this.popupOptions.target = () => import(`${'./gasLeakDetectionAlarmInfoExcelUpload.vue'}`);
  this.popupOptions.isFull = true;
  this.popupOptions.visible = true;
  this.popupOptions.closeCallback = this.closeExcelUploadPopup;
},
closeExcelUploadPopup(_result) {
  this.popupOptions.target = null;
  this.popupOptions.visible = false;
  if (_result && _result.length > 0) {
    let s_data = this.$_.filter(_result, (item) => { return !item.error_message })
    this.$_.forEach(s_data, item => {
      // item.plantCd = this.searchParam.plantCd;
      item.SysRevision = 1;
      item.regUserId = this.$store.getters.user.userId;  // 등록자 ID
      item.chgUserId = this.$store.getters.user.userId;  // 수정자 ID
    })

    this.$http.url = transactionConfig.psi.lbe.gasalarm.insert.url + '/excel';
    this.$http.type = 'POST';
    this.$http.param = s_data;
    this.$http.request(() => {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getList();
    });
  }
},
  }
};
</script>
